import {IProduct} from 'app/blocks/model/product.model';
import {ICustomer} from 'app/blocks/model/customer.model';
import {IRoute} from 'app/blocks/model/route.model';

export interface IReportData {
    route?: IRoute;
    customer?: ICustomer;
    product?: IProduct;
    date?: string;
    saleQuantity?: number;
    returnQuantity?: number;
    returnQuantityPercentage?: number;
    saleAmount?: number;
    returnAmount?: number;
    discountAmount?: number;
    returnAmountPercentage?: number;
    tax?: number;
    total?: number;
    cost?: number;
    margin?: number;
    marginPercentage?: number;
    commissionAmount?: number;
    consignmentFinalQty?: number;
    consignmentValue?: number;
}

export class ReportData implements IReportData {
    public route?: IRoute;
    public customer?: ICustomer;
    public product?: IProduct;
    public date?: string;
    public saleQuantity?: number;
    public returnQuantity?: number;
    public returnQuantityPercentage?: number;
    public saleAmount?: number;
    public returnAmount?: number;
    public discountAmount?: number;
    public returnAmountPercentage?: number;
    public tax?: number;
    public total?: number;
    public cost?: number;
    public margin?: number;
    public marginPercentage?: number;
    public commissionAmount?: number;
    public consignmentFinalQty?: number;
    public consignmentValue?: number;

    constructor(reportData?: IReportData) {
        if (reportData) {
            this.route = reportData.route;
            this.customer = reportData.customer;
            this.product = reportData.product;
            this.date = reportData.date;
            this.saleQuantity = reportData.saleQuantity;
            this.returnQuantity = reportData.returnQuantity;
            this.returnQuantityPercentage = reportData.returnQuantityPercentage;
            this.saleAmount = reportData.saleAmount;
            this.returnAmount = reportData.returnAmount;
            this.discountAmount = reportData.discountAmount;
            this.returnAmountPercentage = reportData.returnAmountPercentage;
            this.tax = reportData.tax;
            this.total = reportData.total;
            this.cost = reportData.cost;
            this.margin = reportData.margin;
            this.marginPercentage = reportData.marginPercentage;
            this.commissionAmount = reportData.commissionAmount;
            this.consignmentFinalQty = reportData.consignmentFinalQty;
            this.consignmentValue = reportData.consignmentValue;
        }
    }
}
